import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect
} from 'react-router-dom';
import Loadable from 'react-loadable';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-modal-slider/lib/main.css';
import Notifications, { notify } from 'react-notify-toast';
import async from 'async';
import _ from 'lodash';
import LoadingComponent from './components/Loader/BasicLoader';
import Header from './components/Header/Header';
import Footer from './components/Footer';
import NotFound from './components/NotFound';
import Sidebar from './components/Sidebar/Sidebar';
import Login from './components/Login/Login';
import callApi from './util/apiCaller';
import callIdApi from "./util/idApiCaller";
import AccessControl from './components/PermissionLocks/AccessControl';
import PageAccessControl from "./components/PermissionLocks/PageAccessControl";
import sidebarLinks from './constants/sidebar';
import BreadCrumb from "./components/BreadCrumb";
import utility from "./util/utility";
import PubNubReact from 'pubnub-react';
import moment from 'moment';
import {version} from '../package.json';

const DIALER_PUBNUB_PUBLISHER_KEY = 'pub-c-c2d3bd6d-c78c-4ca0-be50-b342ce4744ed';
const DIALER_PUBNUB_SUBSCRIBER_KEY = 'sub-c-bee3adec-2f16-11ea-aaf2-c6d8f98a95a1';

const PROTOCOL = window.location.protocol;
const HOST = window.location.host;
const endpoint = `${process.env.REACT_APP_API_URL}`;

let socket;

const AsyncProfile = Loadable({
    loader: () => import('./modules/Profile/Profile/Profile'),
    loading: LoadingComponent,
});
const AsyncUserSettings = Loadable({
    loader: () => import('./modules/Profile/UserSettings/UserSettings'),
    loading: LoadingComponent,
});
const AsyncAdminDashboard = Loadable({
    loader: () => import('./modules/Admin/AdminDashboard/AdminDashboard'),
    loading: LoadingComponent,
});

const AsyncAdminNotifications = Loadable({
    loader: () => import('./modules/Admin/AdminNotifications/AdminNotifications'),
    loading: LoadingComponent,
});

const AsyncAdminAccessList = Loadable({
    loader: () => import('./modules/Admin/AdminAccessList/AdminAccessList'),
    loading: LoadingComponent,
});
const AsyncVendorPhotographerList = Loadable({
    loader: () => import('./modules/Admin/VendorPhotographerList/VendorPhotographerList'),
    loading: LoadingComponent,
});
const AsyncVendorMehendiList = Loadable({
    loader: () => import('./modules/Admin/VendorMehendiList/VendorMehendiList'),
    loading: LoadingComponent,
});
const AsyncVendorMakeupList = Loadable({
    loader: () => import('./modules/Admin/VendorMakeupList/VendorMakeupList'),
    loading: LoadingComponent,
});
const AsyncVenueLeadList = Loadable({
    loader: () => import('./modules/Admin/VenueLeadList/VenueLeadList'),
    loading: LoadingComponent,
});

const AsyncAgentEnquiries = Loadable({
    loader: () => import('./modules/Admin/AgentEnquiries/AgentEnquiries'),
    loading: LoadingComponent,
});
const AsyncAgentList = Loadable({
    loader: () => import('./modules/Admin/AgentList/AgentList'),
    loading: LoadingComponent,
});
const AsyncAgentOnlineList = Loadable({
    loader: () => import('./modules/Admin/AgentOnlineList/AgentOnlineList'),
    loading: LoadingComponent,
});
const AsyncAgentCallData = Loadable({
    loader: () => import('./modules/Admin/AgentCallData/AgentCallData'),
    loading: LoadingComponent,
});
const AsyncAgentLeadDashboard = Loadable({
    loader: () => import('./modules/Admin/AgentLeadDashboard/AgentLeadDashboard'),
    loading: LoadingComponent,
});
const AsyncMyAgentCallData = Loadable({
    loader: () => import('./modules/Admin/MyAgentCallData/MyAgentCallData'),
    loading: LoadingComponent,
});
const AsyncAlgoGraph = Loadable({
    loader: () => import('./modules/BD/AlgoGraph/AlgoGraph'),
    loading: LoadingComponent,
});
const AsyncMyPartnerApproval = Loadable({
    loader: () => import('./modules/BD/MyPartnerApprovals/PartnerApprovalList'),
    loading: LoadingComponent,
});

const AsyncEmailTaskList = Loadable({
    loader: () => import('./modules/Services/EmailTaskList/EmailTaskList'),
    loading: LoadingComponent,
});
const AsyncEmailTemplateList = Loadable({
    loader: () => import('./modules/Services/EmailTemplateList/EmailTemplateList'),
    loading: LoadingComponent,
});
const AsyncSummaryTemplateList = Loadable({
    loader: () => import('./modules/Services/SummaryTemplateList/SummaryTemplateList'),
    loading: LoadingComponent,
});
const AsyncEmailTemplatePreview = Loadable({
    loader: () => import('./modules/Services/EmailTemplatePreview/EmailTemplatePreview'),
    loading: LoadingComponent,
});

const AsyncFeaturedVenueList = Loadable({
    loader: () => import('./modules/BD/FeaturedVenueList/FeaturedVenueList'),
    loading: LoadingComponent,
});

const AsyncOfferList = Loadable({
    loader: () => import('./modules/Corporate/OffersList/OffersList'),
    loading: LoadingComponent,
});
const AsyncOfferUpdator = Loadable({
    loader: () => import('./modules/Corporate/OfferUpdator/OfferUpdator'),
    loading: LoadingComponent,
});

const AsyncBizRuleUpdator = Loadable({
    loader: () => import('./modules/BD/BizRuleUpdator/BizRuleUpdator'),
    loading: LoadingComponent,
});

const AsyncKeywordList = Loadable({
    loader: () => import('./modules/Content/KeywordList/KeywordList'),
    loading: LoadingComponent,
});

const AsyncShortUrlList = Loadable({
    loader: () => import('./modules/Services/ShortUrlList/ShortUrlList'),
    loading: LoadingComponent,
});
const AsyncSmsTaskList = Loadable({
    loader: () => import('./modules/Services/SmsTaskList/SmsTaskList'),
    loading: LoadingComponent,
});
const AsyncWhatsappTaskList = Loadable({
    loader: () => import('./modules/Services/WhatsappTaskList/WhatsappTaskList'),
    loading: LoadingComponent,
});
const AsyncContentList = Loadable({
    loader: () => import('./modules/Services/ContentList/ContentList'),
    loading: LoadingComponent,
});

const AsyncContentUpdator = Loadable({
    loader: () => import('./modules/Services/ContentUpdator/ContentUpdator'),
    loading: LoadingComponent,
});
const AsyncSmsTemplatesList = Loadable({
    loader: () => import('./modules/Services/SmsTemplatesList/SmsTemplatesList'),
    loading: LoadingComponent,
});
const AsyncPdfTemplatesList = Loadable({
    loader: () => import('./modules/Services/PdfTemplatesList/PdfTemplatesList'),
    loading: LoadingComponent,
});
const AsyncWhatsappTemplatesList = Loadable({
    loader: () => import('./modules/Services/WhatsappTemplatesList/WhatsappTemplatesList'),
    loading: LoadingComponent,
});

const AsyncPlanList = Loadable({
    loader: () => import('./modules/Subscriptions/PlanList/PlanList'),
    loading: LoadingComponent,
});
const AsyncChequesList = Loadable({
    loader: () => import('./modules/Subscriptions/ChequesList/ChequesList'),
    loading: LoadingComponent,
});
const AsyncPaymentList = Loadable({
    loader: () => import('./modules/Subscriptions/PaymentList/PaymentList'),
    loading: LoadingComponent,
});
const AsyncSubscriptionList = Loadable({
    loader: () => import('./modules/Subscriptions/SubscriptionList/SubscriptionList'),
    loading: LoadingComponent,
});
const AsyncUploader = Loadable({
    loader: () => import('./modules/Services/Uploader/Uploader'),
    loading: LoadingComponent,
});
const AsyncCityList = Loadable({
    loader: () => import('./modules/Services/CityList/CityList'),
    loading: LoadingComponent,
});

const AsyncFilterList = Loadable({
    loader: () => import('./modules/Services/FilterList/FilterList'),
    loading: LoadingComponent,
});

const AsyncFilterUpdator = Loadable({
    loader: () => import('./modules/Services/FilterUpdator/FilterUpdator'),
    loading: LoadingComponent,
});

const AsyncVenueSubscriptionCreator = Loadable({
    loader: () => import('./modules/Subscriptions/VenueSubscriptionCreator/VenueSubscriptionCreator'),
    loading: LoadingComponent,
});

const AsyncUserList = Loadable({
    loader: () => import('./modules/Admin/UserList/UserList'),
    loading: LoadingComponent,
});
const AsyncUserSearchList = Loadable({
    loader: () => import('./modules/Admin/UserSearchList/UserList'),
    loading: LoadingComponent,
});
const AsyncRoleList = Loadable({
    loader: () => import('./modules/Admin/RoleList/RoleList'),
    loading: LoadingComponent,
});
const AsyncRoleGroupList = Loadable({
    loader: () => import('./modules/Admin/RoleGroupList/RoleGroupList'),
    loading: LoadingComponent,
});

const AsyncModuleList = Loadable({
    loader: () => import('./modules/Admin/ModuleList/ModuleList'),
    loading: LoadingComponent,
});
const AsyncPartnerAppModuleList = Loadable({
    loader: () => import('./modules/Admin/PartnerAppModuleList/ModuleList'),
    loading: LoadingComponent,
});
const AsyncApprovalAgentList = Loadable({
    loader: () => import('./modules/Admin/ApprovalAgentList/ApprovalAgentList'),
    loading: LoadingComponent,
});
const AsyncApprovalList = Loadable({
    loader: () => import('./modules/Admin/Approvals/ApprovalList'),
    loading: LoadingComponent,
});

const AsyncPartnerAccountPermissionUpdator = Loadable({
    loader: () => import('./modules/BD/PartnerPermissionUpdator/PartnerPermissionUpdator'),
    loading: LoadingComponent,
});

const AsyncPermissionList = Loadable({
    loader: () => import('./modules/Admin/PermissionList/PermissionList'),
    loading: LoadingComponent,
});

const AsyncRoleUpdator = Loadable({
    loader: () => import('./modules/Admin/RoleUpdator/RoleUpdator'),
    loading: LoadingComponent,
});


const AsyncUserUpdate = Loadable({
    loader: () => import('./modules/Admin/UserUpdate/UserUpdate'),
    loading: LoadingComponent,
});


const AsyncTasktypeList = Loadable({
    loader: () => import('./modules/Services/TasktypeList/TasktypeList'),
    loading: LoadingComponent,
});
const AsyncEventTemplateList = Loadable({
    loader: () => import('./modules/Services/EventTemplateList/EventTemplateList'),
    loading: LoadingComponent,
});

const AsyncSmsEventList = Loadable({
    loader: () => import('./modules/Marketing/SmsToolkit/EventList/Events'),
    loading: LoadingComponent,
});

const AsyncLeadFormList = Loadable({
    loader: () => import('./modules/Services/LeadFormList/LeadFormList'),
    loading: LoadingComponent,
});
const AsyncAdminFeaturedVenueList = Loadable({
    loader: () => import('./modules/Services/AdminFeaturedVenueList/FeaturedVenueList'),
    loading: LoadingComponent,
});
const AsyncAlertSystemList = Loadable({
    loader: () => import('./modules/Services/AlertSystemList/AlertSystemList'),
    loading: LoadingComponent,
});
const AsyncLeadFormUpdator = Loadable({
    loader: () => import('./modules/Services/LeadFormUpdator/LeadFormUpdator'),
    loading: LoadingComponent,
});


const AsyncRedirectList = Loadable({
    loader: () => import('./modules/Services/RedirectList/RedirectList'),
    loading: LoadingComponent,
});
const AsyncAgentUpdator = Loadable({
    loader: () => import('./modules/Admin/AgentUpdator/AgentUpdator'),
    loading: LoadingComponent,
});

const AsyncBizLeadList = Loadable({
    loader: () => import('./modules/BD/BizLeadList/BizLeadList'),
    loading: LoadingComponent,
});
const AsyncBDLeadAnalytics = Loadable({
    loader: () => import('./modules/BD/BDLeadAnalytics/BDLeadAnalytics'),
    loading: LoadingComponent,
});
const AsyncPackageCostData = Loadable({
    loader: () => import('./modules/BD/PackageCostData/PackageCostData'),
    loading: LoadingComponent,
});
const AsyncPartnerUsersList = Loadable({
    loader: () => import('./modules/BD/PartnerUsersList/PartnerUsersList'),
    loading: LoadingComponent,
});

const AsyncPartnerUserDetail = Loadable({
    loader: () => import('./modules/BD/PartnerUserDetail/PartnerUserDetail'),
    loading: LoadingComponent,
});

const AsyncAgentBdDashboard = Loadable({
    loader: () => import('./modules/Admin/AgentBdDashboard/AgentBdDashboard'),
    loading: LoadingComponent,
});
const AsyncAgentCorpDashboard = Loadable({
    loader: () => import('./modules/Admin/AgentCorpDashboard/AgentCorpDashboard'),
    loading: LoadingComponent,
});
const AsyncAgentFeedbackDashboard = Loadable({
    loader: () => import('./modules/Admin/AgentFeedbackDashboard/AgentFeedbackDashboard'),
    loading: LoadingComponent,
});
const AsyncSalesAgentQueueSystem = Loadable({
    loader: () => import('./modules/Admin/SalesAgentQueueSystem/SalesAgentQueueSystem'),
    loading: LoadingComponent,
});



const PrivateRoute = ({ component: Component, isAuthenticated: isAuthenticated, userPermissions, shouldHavePermissions, ...rest }) => (
    <Route {...rest} render={(props) => (
        isAuthenticated
            ? <PageAccessControl
                userPermissions={userPermissions}
                shouldHavePermissions={shouldHavePermissions}
            >
                <Component {...props} />
            </PageAccessControl>
            : <Redirect to={{
                pathname: '/login',
                state: { _refurl: props.location }
            }} />
    )} />
);

class App extends Component {
    constructor() {
        super();
        this.baseBreadCrumbs = [{
            link: '/profile',
            name: 'Home',
            isActive: false
        }];
        this.state = {
            isMounted: false,
            isLoading: false,
            userid: '',
            agentid: '',
            user: {},
            agent: {},
            isAuthenticated: false,
            token: '',
            notification: {
                message: '',
                link: '',
                timeout: 3,
                msgId: '',
            },
            cities: [],
            userPermissions: [],
            isLoggingIn: false,
            links: [],
            faveLinks: [],
            userprefId: '',
            breadcrumbs: [{
                link: '/profile',
                name: 'Home',
                isActive: false
            }],
        };
        this.isAuthenticated = false;
        this.dialerPubnub = new PubNubReact({
            publishKey: DIALER_PUBNUB_PUBLISHER_KEY,
            subscribeKey: DIALER_PUBNUB_SUBSCRIBER_KEY
        });
        this.dialerPubnub.init(this);
    }

    componentDidMount() {
        console.log('App is running on version :::: ', version);
        let token = window.localStorage.getItem('token');
        if (token && token !== '') {
            this.setState({
                isLoading: true
            });
            // socket = window.io(endpoint);
            this.verifyToken(token, (err, user) => {
                if (err) {
                    window.localStorage.removeItem('token');
                } else {
                    this.isAuthenticated = true;
                    let model = {
                        user: user
                    };
                    if (!_.isEmpty(model.user)) {
                        this.setState({
                            isMounted: true,
                            isLoading: false,
                            isAuthenticated: true,
                            userid: model.user._id,
                            token: token,
                            user: model.user,
                        }, () => {
                            async.series([
                                (cb) => {
                                    if (model.user && model.user.agentid) {
                                        this.fetchAgent(model.user.agentid, (err, agent) => {
                                            if (!err && agent) {
                                                model.agent = agent;
                                                return cb();
                                            } else {
                                                return cb();
                                            }
                                        });
                                    } else {
                                        return cb();
                                    }
                                }, (cb) => {
                                    if (model.user && model.user._id) {
                                        this.fetchUserprefs(model.user._id, (err, userpref) => {
                                            if (!err && userpref) {
                                                model.userprefId = userpref._id;
                                                model.faveLinks = userpref.faveLinks;
                                                return cb();
                                            } else {
                                                return cb();
                                            }
                                        });
                                    } else {
                                        return cb();
                                    }
                                },
                                (cb) => {
                                    if (model.user && model.user.venue_list && model.user.venue_list !== '') {
                                        this.fetchAccessList(model.user.venue_list, (err, access) => {
                                            if (!err && access) {
                                                model.access = access;
                                                return cb();
                                            } else {
                                                return cb();
                                            }
                                        });
                                    } else {
                                        return cb();
                                    }
                                },
                                (cb) => {
                                    this.fetchCityList((err, cities) => {
                                        if (!err && cities && cities.length > 0) {
                                            model.cities = cities.map(item => {
                                                return {
                                                    name: _.startCase(item.name),
                                                    sname: item.name
                                                }
                                            });
                                            return cb();
                                        } else {
                                            return cb();
                                        }
                                    });
                                }
                            ], (err) => {
                                if (err) {
                                    this.setState({
                                        isLoading: false
                                    });
                                } else {
                                    let userPermissions = [];
                                    if (model.user.userPermissions && _.isArray(model.user.userPermissions)) {
                                        userPermissions = userPermissions.concat(model.user.userPermissions);
                                    }
                                    if (model.user.extraPermissions && _.isArray(model.user.extraPermissions)) {
                                        userPermissions = userPermissions.concat(model.user.extraPermissions);
                                    }
                                    this.setState({
                                        isLoading: false,
                                        user: model.user ? model.user : {},
                                        userPermissions: userPermissions,
                                        agent: model.agent ? model.agent : {},
                                        agentid: model.user ? model.user.agentid : '',
                                        access: model.access ? model.access : {},
                                        cities: model.cities,
                                        userprefId: model.userprefId ? model.userprefId : '',
                                        faveLinks: model.faveLinks ? model.faveLinks : [],
                                        links: sidebarLinks
                                    });
                                    if (this.state.agentid) {
                                        this.dialerPubnub.subscribe({
                                            channels: [`dialer-${this.state.agentid}`],
                                            withPresence: false
                                        });
                                    }
                                }
                            });
                        });
                    }
                }
            });
            // socket.on('MarkedOnline', function(data){
            //     console.log('socket:' + socket.id);
            // })
        }
    }

    // setAgentOnline = (agentId, socketId) => {
    //     socket.emit('agentOnline', { agentId: agentId , type: 'vm-manager', socketId: socketId });
    // };
    //
    // setAgentOffline = (agentId, socketId) => {
    //     socket.emit('agentOffline', { agentId: agentId , type: 'vm-manager', socketId: socketId });
    // };

    connectCall = (phone) => {
        if (phone && utility.isValidIndianMobile(phone)) {
            let publishConfig = {
                channel: `dialer-${this.state.agentid}`,
                message: {
                    agent_id: this.state.agentid,
                    contact_phone: phone,
                    expiry_time: new Date(moment().add(10, 'seconds').toISOString()).getTime()
                }
            };
            this.dialerPubnub.publish(publishConfig, (status, response) => {
                if (status.statusCode === 200) {
                    this.showMessage('Processed', 'success');
                } else {
                    this.showMessage('Failed', 'danger');
                }
            });
        } else {
            this.showMessage('Please enter valid Indian Number', 'danger');
        }
    };

    // connectCall = (phone) => {
    //       if (phone && utility.isValidIndianMobile(phone)) {
    //TODO v1 to v3 change ritwik

    //           callApi(`v3/admin-dashboard/agent/${this.state.agentid}/push-to-call`, 'post', {
    //               phone: phone
    //           }).then(res => {
    //               if (res.status === 'Success') {
    //                   this.showMessage('Processed', 'success');
    //               } else {
    //                   this.showMessage('Failed', 'danger');
    //               }
    //           });
    //       } else {
    //           this.showMessage('Please enter valid Indian Number', 'danger');
    //       }
    //   };

    // connectCall = (phone) => {
    //     if (phone && utility.isValidIndianMobile(phone)) {
    //         let data = {
    //             agentId :this.state.agentid,
    //             phone: phone
    //         };
    //         socket.emit('connectCall', data);
    //         this.showMessage('Processed', 'success');
    //     } else {
    //         this.showMessage('Please enter valid Indian Number', 'danger');
    //     }
    // };


    updateUser = (user) => {
        this.setState({ user: user })
    }

    fetchUser = (userid, next) => {
        callApi(`v2/users/${userid}`, 'GET').then(res => {
            return next(null, res.data.user);
        });
    };

    fetchUserprefs = (userid, next) => {
        callApi(`v3/users/${userid}/faves`, 'GET').then(res => {
            return next(null, res && res.data ? res.data.userpref : null);
        });
    };

    updateUserpref = (userpref) => {
        callApi(`v2/userpref/${this.state.userprefId}/update`, 'POST', {
            userpref: userpref
        }).then(res => {
            if (res.status === 'Success') {
                this.setState({
                    faveLinks: res.data.userpref.faveLinks
                });
                this.showMessage('Links Updated', 'success');
            }
        });
    };

    updateAgent = (agent) => {
        callIdApi(`v2/agents/${this.state.agentid}/update`, 'POST', {
            agent: agent
        }).then(res => {
            if (res.status === 'Success') {
                this.setState({
                    agent: res.data.agent
                });
                this.showMessage('Updated', 'success');
            }
        });
    };

    fetchCityList = (next) => {
        callApi(`v3/citys/city-list`, 'GET').then(res => {
            return next(null, res.data.cities);
        });
    };

    fetchAgent = (agentid, next) => {
        callIdApi(`v2/agents/${agentid}`, 'GET').then(res => {
            return next(null, res.data.agent);
        });
    };

    fetchAgentUnReadMessageCount = (userid, next) => {
        callApi(`v3/users/${userid}/unread-notifications`, 'GET').then(res => {
            return next(null, res.data.count);
        });
    };

    loginUser = (creds) => {
        this.setState({
            isLoggingIn: true
        });
        // callApi(`auth/login`, 'post', creds).then(res => { /* MANAGER AUTH CALL API */
        callIdApi(`auth/login`, 'post', creds).then(res => { /* ID AUTH CALL API */
            this.setState({
                isLoggingIn: false
            });
            if (res.user && res.user.ok) {
                window.localStorage.setItem('token', res.token);
                window.location = "/";
            } else {
                this.showMessage(res.message, 'error');
            }
        });
    };

    verifyToken = (token, next) => {
        callIdApi(`auth/verify`, 'post', {
            token: token
        }).then(res => {
            if (res.user && res.user.ok) {
                let user = res.user.data;
                return next(null, user);
            } else {
                return next('Unauthorized');
            }
        });
    };

    logoutUser = () => {
        // if (socket && this.state.agentid) {
        //     this.setAgentOffline(this.state.agentid, socket.id);
        // }
        window.localStorage.removeItem('token');
        this.isAuthenticated = false;
        window.location = '/';
    };

    showMessage = (msg, msgtype) => {
        notify.show(msg, msgtype, 3000);
    };

    notifyToAgent = (msg) => {
        let text = msg.text;
        let options = {};
        if (msg.isTimedNotification) {
            options.autoClose = msg.timer;
        } else {
            options.autoClose = false;
        }
        if (msg.notification_type === 'success') {
            toast.success(text, options);
        } else {
            toast.info(text, options);
        }
    };

    clearAgentNotifications = () => {
        toast.dismiss();
    };

    fetchAccessList = (id, next) => {
        callApi(`v2/venueaccesses/${id}/populated`, 'get').then(res => {
            if (res.status === 'Success') {
                let access = res.data.venueaccess;
                return next(null, access);
            } else {
                return next('Unauthorized');
            }
        });
    };

    notifyToAdmin = (message, link, timeout) => {
        let notification = this.state.notification;
        notification.message = message;
        notification.link = link ? link : '';
        notification.timeout = timeout;
        notification.msgId = this.generateMessageId(8);
        this.setState({
            notification: notification,
        });
    };

    rand = (min, max) => {
        let random = Math.random();
        return Math.floor(random * (max - min) + min);
    };

    generateMessageId = (length) => {
        let loopLength = length || 8;
        let allowsChars = '0123456789';
        let password = '';
        for (let index = 0; index < loopLength; ++index) {
            let charIndex = this.rand(0, allowsChars.length - 1);
            password += allowsChars[charIndex];
        }
        return password;
    };

    setBreadCrumbs = (breadcrumbs) => {
        this.setState({
            breadcrumbs: breadcrumbs
        })
    };

    resetBreadCrumbs = () => {
        this.setState({
            breadcrumbs: this.baseBreadCrumbs
        });
    };

    // sendPubNubMessage = ( message) => {
    //     this.pubnub.publish(
    //         {
    //             channel: channels[0],
    //             message,
    //         },
    //         () => {
    //
    //         }
    //     );
    // };

    render() {
        const cities = this.state.cities;
        return (
            <div className="App">
                <Router>
                    <div id="page-content-wrapper">
                        {this.state.isAuthenticated ?
                            <header id="topnav"
                                className="navbar navbar-default navbar-fixed-top"
                                role="banner">
                                <Header
                                    notification={this.state.notification}
                                    fetchAgentUnReadMessageCount={this.fetchAgentUnReadMessageCount}
                                    user={this.state.user}
                                    showMessage={this.showMessage}
                                    notifyToAdmin={this.notifyToAgent}
                                    clearAgentNotifications={this.clearAgentNotifications}
                                    logOut={this.logoutUser}
                                />
                            </header>
                            :
                            null
                        }
                        <div id="wrapper">
                            <div id="layout-static">
                                {this.state.isAuthenticated ?
                                    <Sidebar
                                        faveLinks={this.state.faveLinks}
                                        links={this.state.links}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    :
                                    null
                                }
                                <div className="static-content-wrapper">
                                    <div className="static-content">
                                        <div className="page-content">
                                            <BreadCrumb
                                                breadcrumbs={this.state.breadcrumbs}
                                            />
                                            <div className="container-fluid"
                                                style={{ minHeight: '550px' }}>
                                                <Switch>
                                                    <Route exact path="/login"
                                                        render={props =>
                                                            <Login {...props}
                                                                isLoggingIn={this.state.isLoggingIn}
                                                                isAuthenticated={this.state.isAuthenticated}
                                                                user={this.state.user}
                                                                loginUser={this.loginUser} />} />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={null}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact path="/"
                                                        component={props =>
                                                            <AsyncProfile
                                                                {...props}
                                                                userPermissions={this.state.userPermissions}
                                                                showMessage={this.showMessage}
                                                                updateUserpref={this.updateUserpref}
                                                                links={this.state.links}
                                                                agent={!_.isEmpty(this.state.agent) ? this.state.agent : {}}
                                                                user={this.state.user}
                                                                updateUser={this.updateUser}
                                                            />} />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={null}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact path="/settings"
                                                        component={props =>
                                                            <AsyncUserSettings
                                                                {...props}
                                                                userPermissions={this.state.userPermissions}
                                                                updateUserpref={this.updateUserpref}
                                                                updateAgent={this.updateAgent}
                                                                links={this.state.links}
                                                                user={this.state.user}
                                                                agent={this.state.agent}
                                                                showMessage={this.showMessage}
                                                            />
                                                        }
                                                    />
                                                    <Redirect
                                                        path={'/profile'}
                                                        to={"/"} />
                                                    <Redirect
                                                        path={'/manager/profile'}
                                                        to={"/"} />
                                                   
                                                  
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/users/list"
                                                        component={props =>
                                                            <AsyncUserList {...props}
                                                                userPermissions={this.state.userPermissions}
                                                                showMessage={this.showMessage}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                                userid={this.state.userid}
                                                                agent={this.state.agent} />}
                                                    />
                                                    
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/admin/role/list"
                                                        component={props =>
                                                            <AsyncRoleList {...props}
                                                                userPermissions={this.state.userPermissions}
                                                                showMessage={this.showMessage}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                                userid={this.state.userid}
                                                                agent={this.state.agent} />}
                                                    />
                                                    
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/admin/rolegroup/list"
                                                        component={props =>
                                                            <AsyncRoleGroupList {...props}
                                                                userPermissions={this.state.userPermissions}
                                                                showMessage={this.showMessage}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                                userid={this.state.userid}
                                                                agent={this.state.agent} />}
                                                    />

                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/admin/module/list"
                                                        component={props =>
                                                            <AsyncModuleList {...props}
                                                                userPermissions={this.state.userPermissions}
                                                                showMessage={this.showMessage}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                                userid={this.state.userid}
                                                                agent={this.state.agent} />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/admin/partner-app/module/list"
                                                        component={props =>
                                                            <AsyncPartnerAppModuleList {...props}
                                                                userPermissions={this.state.userPermissions}
                                                                showMessage={this.showMessage}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                                userid={this.state.userid}
                                                                agent={this.state.agent} />}
                                                    />
                                                    
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/partner/user/:userId/partner-account-permission"
                                                        component={props =>
                                                            <AsyncPartnerAccountPermissionUpdator {...props}
                                                                userPermissions={this.state.userPermissions}
                                                                showMessage={this.showMessage}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                                userid={this.state.userid}
                                                                cities={cities ? cities : []}
                                                                agent={this.state.agent} />}
                                                    />

                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/admin/permission/list"
                                                        component={props =>
                                                            <AsyncPermissionList {...props}
                                                                userPermissions={this.state.userPermissions}
                                                                showMessage={this.showMessage}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                                userid={this.state.userid}
                                                                agent={this.state.agent} />}
                                                    />

                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/admin/role/:id/update"
                                                        component={props =>
                                                            <AsyncRoleUpdator {...props}
                                                                showMessage={this.showMessage}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                                userid={this.state.userid}
                                                                agent={this.state.agent} />}
                                                    />
                                                  
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/agents/list"
                                                        component={props =>
                                                            <AsyncAgentList {...props}
                                                                userPermissions={this.state.userPermissions}
                                                                showMessage={this.showMessage}
                                                                cities={cities ? cities : []}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                                userid={this.state.userid}
                                                                agent={this.state.agent} />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/agentonline/list"
                                                        component={props =>
                                                            <AsyncAgentOnlineList {...props}
                                                                userPermissions={this.state.userPermissions}
                                                                showMessage={this.showMessage}
                                                                cities={cities ? cities : []}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                                userid={this.state.userid}
                                                                agent={this.state.agent} />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/agents/call-data"
                                                        component={props =>
                                                            <AsyncAgentCallData {...props}
                                                                userPermissions={this.state.userPermissions}
                                                                showMessage={this.showMessage}
                                                                cities={cities ? cities : []}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                                userid={this.state.userid}
                                                                agent={this.state.agent} />}
                                                    />
                                                    
                                                    <Redirect
                                                        exact
                                                        from="/manager/venue/featured"
                                                        to={"/manager/business-development/venue-partners"}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["bd-role:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/business-development/venue-partners"
                                                        component={props =>
                                                            <AsyncFeaturedVenueList {...props}
                                                                cities={cities}
                                                                showMessage={this.showMessage}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/mypartner-approval/list"
                                                        component={props =>
                                                            <AsyncMyPartnerApproval {...props}
                                                                cities={cities}
                                                                showMessage={this.showMessage}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                            />}
                                                    />
                                                   

                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/corporate/offers"
                                                        component={props =>
                                                            <AsyncOfferList
                                                                {...props}
                                                                cities={cities ? cities : []}
                                                                showMessage={this.showMessage}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                                userid={this.state.userid}
                                                                agent={this.state.agent}
                                                            />
                                                        }
                                                    />
                                                   
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/vendor/makeup-artists-list"
                                                        component={props =>
                                                            <AsyncVendorMakeupList {...props}
                                                                cities={cities}
                                                                showMessage={this.showMessage}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/venue/:id/venue-leads"
                                                        component={props =>
                                                            <AsyncVenueLeadList {...props}
                                                                cities={cities}
                                                                showMessage={this.showMessage}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    
                                                    <Redirect
                                                        exact
                                                        from="/manager/venue/featured"
                                                        to={"/manager/business-development/venue-partners"}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["bd-role:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/business-development/venue-partners"
                                                        component={props =>
                                                            <AsyncFeaturedVenueList {...props}
                                                                cities={cities}
                                                                showMessage={this.showMessage}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/venue/algo-test"
                                                        component={props =>
                                                            <AsyncAlgoGraph {...props}
                                                                cities={cities}
                                                                showMessage={this.showMessage}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    
                                                    {/*services*/}
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/services/city/list"
                                                        component={props =>
                                                            <AsyncCityList {...props}
                                                                userPermissions={this.state.userPermissions}
                                                                showMessage={this.showMessage}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/lead/filter/list"
                                                        component={props =>
                                                            <AsyncFilterList {...props}
                                                                showMessage={this.showMessage}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/services/filter/:id/update"
                                                        component={props =>
                                                            <AsyncFilterUpdator {...props}
                                                                showMessage={this.showMessage}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/services/sms/templates"
                                                        component={props =>
                                                            <AsyncSmsTemplatesList {...props}
                                                                userPermissions={this.state.userPermissions}
                                                                showMessage={this.showMessage}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/services/pdf/templates"
                                                        component={props =>
                                                            <AsyncPdfTemplatesList {...props}
                                                                userPermissions={this.state.userPermissions}
                                                                showMessage={this.showMessage}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                            shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated} 
                                                                  path="/manager/services/whatsapp/templates"
                                                                  component={props =>
                                                                      <AsyncWhatsappTemplatesList {...props}
                                                                                                  showMessage={this.showMessage}
                                                                                                 user={this.state.user}
                                                                                                  agentid={this.state.agentid}
                                                                      />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/services/sms/tasks"
                                                        component={props =>
                                                            <AsyncSmsTaskList {...props}

                                                                showMessage={this.showMessage}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                            shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated} 
                                                                 path="/manager/services/whatsapp/tasks"
                                                                  component={props =>
                                                                    <AsyncWhatsappTaskList {...props}
                                                                                            showMessage={this.showMessage}
                                                                                       user={this.state.user}
                                                                                            agentid={this.state.agentid}
                                                                      />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/services/email/templates"
                                                        component={props =>
                                                            <AsyncEmailTemplateList {...props}
                                                                userPermissions={this.state.userPermissions}
                                                                showMessage={this.showMessage}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/services/summary/templates"
                                                        component={props =>
                                                            <AsyncSummaryTemplateList {...props}
                                                                userPermissions={this.state.userPermissions}
                                                                showMessage={this.showMessage}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/services/email/:id/template-edit"
                                                        component={props =>
                                                            <AsyncEmailTemplatePreview {...props}
                                                                userPermissions={this.state.userPermissions}
                                                                showMessage={this.showMessage}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/services/email/tasks"
                                                        component={props =>
                                                            <AsyncEmailTaskList {...props}
                                                                showMessage={this.showMessage}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/services/content/list"
                                                        component={props =>
                                                            <AsyncContentList {...props}
                                                                userPermissions={this.state.userPermissions}
                                                                showMessage={this.showMessage}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/services/content/:id/update"
                                                        component={props =>
                                                            <AsyncContentUpdator {...props}
                                                                showMessage={this.showMessage}
                                                                userid={this.state.userid}
                                                            />} />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/services/scripts/file-upload"
                                                        component={props =>
                                                            <AsyncUploader {...props}
                                                                userid={this.state.userid}
                                                                showMessage={this.showMessage}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/services/short-url/list"
                                                        component={props =>
                                                            <AsyncShortUrlList {...props}
                                                                userid={this.state.userid}
                                                                showMessage={this.showMessage}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/services/keywords/list"
                                                        component={props =>
                                                            <AsyncKeywordList {...props}
                                                                userPermissions={this.state.userPermissions}
                                                                cities={cities}
                                                                user={this.state.user}
                                                                userid={this.state.userid}
                                                                showMessage={this.showMessage}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/services/paid-partner/list"
                                                        component={props =>
                                                            <AsyncAdminFeaturedVenueList {...props}
                                                                userPermissions={this.state.userPermissions}
                                                                cities={cities}
                                                                user={this.state.user}
                                                                userid={this.state.userid}
                                                                showMessage={this.showMessage}
                                                            />}
                                                    />
                                                    {/*subscriptions*/}
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["subscriptions:create"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/services/subscriptions/create"
                                                        component={props =>
                                                            <AsyncVenueSubscriptionCreator {...props}
                                                                cities={cities}
                                                                userid={this.state.userid}
                                                                showMessage={this.showMessage}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["subscriptions:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/services/subscriptions/list"
                                                        component={AsyncSubscriptionList} />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["plans:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/services/plans/list"
                                                        component={AsyncPlanList} />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["payments:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/services/payments/list"
                                                        component={AsyncPaymentList} />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["cheques:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/services/cheques/list"
                                                        component={AsyncChequesList} />
                                                    
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/services/tasktypes/list"
                                                        component={props =>
                                                            <AsyncTasktypeList {...props}
                                                                userPermissions={this.state.userPermissions}
                                                                showMessage={this.showMessage}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/services/event-templates/list"
                                                        component={props =>
                                                            <AsyncEventTemplateList {...props}
                                                                userPermissions={this.state.userPermissions}
                                                                showMessage={this.showMessage}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["marketing:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/services/smstoolkit/events"
                                                        component={props =>
                                                            <AsyncSmsEventList {...props}
                                                                cities={cities ? cities : []}
                                                                showMessage={this.showMessage}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                            />}
                                                    />
                                                   
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/services/leadform/list"
                                                        component={props =>
                                                            <AsyncLeadFormList {...props}
                                                                userPermissions={this.state.userPermissions}
                                                                showMessage={this.showMessage}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/services/alertsystem/list"
                                                        component={props =>
                                                            <AsyncAlertSystemList {...props}
                                                                userPermissions={this.state.userPermissions}
                                                                showMessage={this.showMessage}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/services/leadform/list"
                                                        component={props =>
                                                            <AsyncLeadFormList {...props}
                                                                showMessage={this.showMessage}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/services/redirect/list"
                                                        component={props =>
                                                            <AsyncRedirectList {...props}
                                                                userPermissions={this.state.userPermissions}
                                                                showMessage={this.showMessage}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/services/leadform/:id/update"
                                                        component={props =>
                                                            <AsyncLeadFormUpdator {...props}
                                                                showMessage={this.showMessage}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                            />}
                                                    />
                                                   
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["bd-role:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/business-development/leads"
                                                        component={props =>
                                                            <AsyncBizLeadList {...props}
                                                                showMessage={this.showMessage}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                                cities={cities ? cities : []}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["bd-role:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/business-development/lead-analytics"
                                                        component={props =>
                                                            <AsyncBDLeadAnalytics {...props}
                                                                showMessage={this.showMessage}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                                cities={this.state.cities}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["packagecosts:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/business-development/package-cost"
                                                        component={props =>
                                                            <AsyncPackageCostData {...props}
                                                                userPermissions={this.state.userPermissions}
                                                                showMessage={this.showMessage}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                                cities={this.state.cities}
                                                            />}
                                                    />
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={[]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/partner-app/users/list"
                                                        component={props =>
                                                            <AsyncPartnerUsersList {...props}
                                                                userPermissions={this.state.userPermissions}
                                                                showMessage={this.showMessage}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                                cities={this.state.cities}
                                                            />}
                                                    />
                                                    
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["bd-role:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        path="/manager/partner/users/list/:id/view-details"
                                                        component={props =>
                                                            <AsyncPartnerUserDetail {...props}
                                                                userPermissions={this.state.userPermissions}
                                                                showMessage={this.showMessage}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                                cities={this.state.cities}
                                                            />}
                                                    />
                                                    
                                                    <PrivateRoute
                                                        userPermissions={this.state.userPermissions}
                                                        shouldHavePermissions={["admin:read"]}
                                                        isAuthenticated={this.state.isAuthenticated}
                                                        exact
                                                        path="/manager/services/email/:id/template-edit"
                                                        component={props =>
                                                            <AsyncEmailTemplatePreview {...props}
                                                                userPermissions={this.state.userPermissions}
                                                                showMessage={this.showMessage}
                                                                user={this.state.user}
                                                                agentid={this.state.agentid}
                                                            />}
                                                    />
                                                   
                                                    <Route
                                                        component={NotFound} />
                                                </Switch>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.isAuthenticated ?
                                        <footer role="contentinfo">
                                            <Footer />
                                        </footer>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Router>
                <ToastContainer
                    newestOnTop={true}
                />
                <Notifications options={{ zIndex: 10000 }} />
            </div>
        );
    }
}

export default App;
