import React, {Component} from 'react';
import {Button, Form, FormControl} from 'react-bootstrap';
import {Redirect} from 'react-router-dom';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPasswordVisible: false,
        };
    }

    submitForm = (e) => {
        e.preventDefault();
        let creds = {
            email: this.username.value,
            password: window.btoa(this.userpass.value),
        };
        this.props.loginUser(creds);
    };

    togglePasswordVisibility = () => {
        this.setState({
            isPasswordVisible: !this.state.isPasswordVisible
        });
    };

    render() {
        if (this.props.isAuthenticated) {
            return <Redirect
                to={this.props.location && this.props.location.state && this.props.location.state._refurl ? this.props.location.state._refurl : '/'}/>
        } else {
            return (
                <div className="container" style={{marginTop: 50}}>
                    <div className="row">
                        <div className="col-md-4 col-md-offset-4">
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    <h2>VenueMonk Login</h2>
                                </div>
                                <div className="panel-body">
                                    <Form onSubmit={(e) => this.submitForm(e)} className="form-horizontal"
                                          id="validate-form">
                                        <div className="form-group mb-md">
                                            <div className="col-xs-12">
                                                <div className="input-group" id="login-email">
                  <span className="input-group-addon">
                    <i className="ti ti-user"/>
                  </span>
                                                    <FormControl
                                                        inputRef={ref => {
                                                            this.username = ref;
                                                        }}
                                                        type="text" name="email"
                                                        placeholder="Username"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group mb-md">
                                            <div className="col-xs-12">
                                                <div className="input-group" id="login-password">
                  <span className="input-group-addon">
                    <i className="ti ti-key"/>
                  </span>
                                                    <FormControl
                                                        inputRef={ref => {
                                                            this.userpass = ref;
                                                        }}
                                                        type={this.state.isPasswordVisible ? 'text' : 'password'}
                                                        name="password"
                                                        id="exampleInputPassword1" placeholder="Password"/>
                                                    <span className="input-group-addon"
                                                          onClick={() => this.togglePasswordVisibility()}>
                    <i className={this.state.isPasswordVisible ? "ti ti-lock" : 'ti ti-eye'}/>
                  </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group mb-n">
                                            <div className="col-xs-12">
                                                <Button
                                                    disabled={this.props.isLoggingIn}
                                                    onClick={(e) => this.submitForm(e)}
                                                    className="btn btn-primary pull-right" type="submit"
                                                    id="login-btn">Login
                                                </Button>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            );
        }
    }
}

export default Login;

