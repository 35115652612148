import React, {Component} from 'react';
// import ManagerSearchHeader from '../../modules/ManagerSearchHeader/ManagerSearchHeader';
import UserDropdown from './UserDropdown';
import PubNubReact from 'pubnub-react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import NotificationWrapper from "../../components/Notifications/NotificationWrapper";

class Header extends Component{
    constructor() {
        super();
        this.state = {
            isUserDropdownOpen: false,
            isSidebarHidden: false,
            notificationCount: 0
        };
        this.pubnub = new PubNubReact({
            publishKey : 'pub-c-c4fa1fec-6b22-4fa2-92e0-26c82ae810ae',
            subscribeKey : 'sub-c-974d3f7a-d31f-11e5-a9b2-02ee2ddab7fe'
        });
        this.pubnub.init(this);
    }

    componentDidMount() {
        document.body.classList.toggle('sidebar-collapsed', false);
        if(this.props.user && this.props.user._id) {
            this.pubnub.subscribe({
                channels: [`${this.props.user._id}`],
                withPresence: true
            });
            this.pubnub.getMessage(`${this.props.user._id}`, (msg) => {
                // this.props.fetchAgentUnReadMessageCount(this.props.user._id, (err, count) => {
                //     console.log(count);
                //     this.setState({
                //         notificationCount: count
                //     });
                // });
                let message = {};
                message.text = msg.message;
                message.timer = 3000;
                message.notification_type = 'success';
                message.isTimedNotification = true;
                // this.props.showMessage(msg.message.text, 'success');
                this.props.notifyToAdmin(message, '/manager/funnel/sales-board', 3);
            });
        }

    }

    componentDidUpdate(prevProps) {
        if(_.isEmpty(prevProps.user) && !_.isEmpty(this.props.user)) {
            this.pubnub.subscribe({
                channels: [`${this.props.user._id}`],
                withPresence: true
            });
            this.pubnub.getMessage(`${this.props.user._id}`, (msg) => {
                // this.props.fetchAgentUnReadMessageCount(this.props.user._id, (err, count) => {
                //     console.log(count);
                //     this.setState({
                //         notificationCount: count
                //     });
                // });
                let message = {};
                message.text = msg.message;
                message.timer = 3000;
                message.notification_type = 'success';
                message.isTimedNotification = true;
                // this.props.showMessage(msg.message.text, 'success');
                this.props.notifyToAdmin(message, '/manager/funnel/sales-board', 3);
            });
        }
    }

    componentWillUnmount() {
        document.body.classList.remove('sidebar-collapsed');
        this.pubnub.unsubscribe({
            channels: [`${this.props.user._id}`]
        });
    }

    toggleUserDropdown = () => {
         this.setState({
             isUserDropdownOpen : !this.state.isUserDropdownOpen
         });
    };

    toggleSidebar = () => {
        this.setState({
            isSidebarHidden: !this.state.isSidebarHidden
        }, () => {
            document.body.classList.toggle('sidebar-collapsed', this.state.isSidebarHidden)
        });
    };

    render() {
        return(
            <div>
                <div className="logo-area">
    <span id="trigger-sidebar" className="toolbar-trigger toolbar-icon-bg">
      <a onClick={() => this.toggleSidebar()} data-toggle="tooltips" data-placement="right" title="Toggle Sidebar">
        <span className="icon-bg">
          <i className="ti ti-menu" />
        </span>
      </a>
    </span>
                    <a style={{marginTop:'12px'}} href="/">
                        <img src="https://venuemonk-images.s3.amazonaws.com/images/logo-v3.png" alt="VenueMonk Logo" />
                    </a>
                </div>{/* logo-area */}
                <div className="toolbar-icon-bg hidden-xs" id="toolbar-search">
                    {/* <ManagerSearchHeader /> */}
                </div>
                <ul className="nav navbar-nav toolbar pull-right">
                    <li className="dropdown toolbar-icon-bg">
                        <a
                            onClick={() => this.props.clearAgentNotifications()}
                        ><span className="icon-bg">
          <i className="ti ti-close" /></span>
                        </a>
                    </li>
                    <li className="dropdown toolbar-icon-bg">
                        <Link to="/manager/notifications"><span className="icon-bg">
          <i className="ti ti-bell" /></span>
                            <span id="vm-manager-notifications" className={this.state.notificationCount > 0 ? "badge badge-deeporange" : "badge badge-deeporange hide"}>{this.state.notificationCount}</span>
                        </Link>
                    </li>
                    <li className={this.state.isUserDropdownOpen ? "dropdown toolbar-icon-bg open" : "dropdown toolbar-icon-bg"}>
                        <a onClick={() => this.toggleUserDropdown()} className="dropdown-toggle username" data-toggle="dropdown">
                            <span className="icon-bg"><i className="ti ti-user" /></span>
                        </a>
                        <UserDropdown
                            logOut={this.props.logOut}
                        />
                    </li>
                </ul>
                <NotificationWrapper
                    notification={this.props.notification}
                />
            </div>
        );
    }
}

export default Header;
